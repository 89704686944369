import React, { useState } from "react"
import styled from "styled-components"
import { rem } from "../../utilities/pxtorem"
import Icon from "../shared/icon"
import FadeIn from "../shared/fade-up-inview"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Box = styled.li`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  ol {
    list-style: decimal inside;
  }

  ol,
  ul {
    padding-bottom: 3vw;

    &:last-child {
      padding-bottom: 0;
    }
  }

  li {
    padding-bottom: 1vw;
  }
`

const Button = styled.button`
  padding: ${rem(24)} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${props => props.theme.mint};
    width: 100%;
    height: 100%;
    z-index: 0;
    transition: opacity 0.5s;
    opacity: 0;
  }

  > span {
    color: ${props => props.theme.mint};
    position: relative;
    z-index: 2;
    text-align: left;
  }

  div {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: solid 2px #fff; */
    border-radius: 100%;
    position: relative;
    z-index: 1;
    transition: transform 0.5s;

    svg {
      width: ${rem(30)};
      height: ${rem(30)};
    }
  }

  &:hover {
    &:after {
      opacity: 0.05;
    }
  }

  &.is-true {
    &:after {
      opacity: 0.01;
    }

    div {
      transform: rotateZ(135deg);

      svg {
        stroke: #ff875c;
      }
    }
  }
`

const Content = styled.section`
  overflow: hidden;
  display: none;
  padding: 1rem 2rem 3rem;

  p,
  li {
    line-height: 1.8;
  }

  p {
    padding-bottom: 1.5rem;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &.is-true {
    display: block;
  }
`

const Ele = ({ title, children }) => {
  const [show, setShow] = useState(false)

  const toggleItem = () => {
    setShow(!show)
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Emergencies Question - " + title,
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "Groups Campaign",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: 1,
    })
  }

  return (
    <Box>
      <FadeIn>
        <Button
          onClick={toggleItem}
          className={"is-" + show}
        >
          <span>{title}</span>
          <div>
            <Icon title="plus-fill" fill="#00e2c1" />
          </div>
        </Button>
        <Content className={"is-" + show}>{children}</Content>
      </FadeIn>
    </Box>
  )
}
export default Ele
