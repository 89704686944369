import React from "react"
import styled from "styled-components"
import { vw } from "../../utilities/pxtovw"

const Box = styled.section`
  padding: ${vw(131)} 0 ${vw(72)};
  position: relative;

  &.clear-bottom {
    padding-bottom: 0;
  }
  /* 
  &.padd-top-large {
    padding-top: ${vw(210)};
  } */

  &.clear-top {
    padding-top: 0;
  }

  &.padd-top-small {
    padding-top: 2vw;
  }

  @media screen and (max-width: 766px) {
    padding-bottom: 3rem;
  }
`

const Ele = ({ children, className }) => {
  return <Box className={className}>{children}</Box>
}
export default Ele
