import React, { useEffect, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import Grid from "./column-grid"
import Slider from "./slider"
import { vw } from "../../utilities/pxtovw"
import Overline from "./overline"
import { useGlobalStateContext } from "../../utilities/context"
import Icon from "../shared/icon"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAverageReturnSeries } from "./planner/utils/get-average-return-series"
import wNumb from "wnumb"

const Controls = styled.div`
  position: relative;
  padding: ${vw(56)};
  width: 55%;
  background-color: rgba(255, 255, 255, 0.05);
  margin-left: ${vw(108)};

  @media screen and (max-width: 766px) {
    margin: 0 2rem;
    width: auto;
    padding: 2rem;
    padding-bottom: 3rem;
    margin-top: 2rem;
  }
`

const Results = styled.div`
  padding: ${vw(56)};
  background-color: ${props => props.theme.mint};
  color: ${props => props.theme.darkblue};
  margin-right: ${vw(108)};
  width: 45%;
  position: relative;

  > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  @media screen and (max-width: 766px) {
    margin: 0 2rem;
    width: auto;
    padding: 2rem;
  }
`

const SimulateBtn = styled.div`
  text-align: right;
  position: relative;
  top: 1.9rem;
  padding-top: 0.5rem;

  button {
    color: ${props => props.theme.mint};
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    background-color: ${props => props.theme.mint};
    color: #000;

    &:hover {
      svg {
        transform: translate3d(0.25rem, 0, 0);
      }
    }

    span {
      text-transform: uppercase;
      font-weight: 500;
      padding-right: 0.75rem;
      position: relative;
      top: -0.25rem;
      font-size: 0.875rem;
    }

    svg {
      position: relative;
      top: 0.125rem;
      transition: transform 0.45s;
      stroke: #000;
    }
  }

  @media screen and (max-width: 766px) {
    top: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
`

const Row = styled.div`
  width: 50%;
  padding-bottom: 1rem;
  margin-bottom: 1.3rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 766px) {
    padding-bottom: 1.625rem;
  }

  span {
    color: ${props => props.theme.darkblue};
    letter-spacing: 0;
  }

  .value {
    font-weight: 500;
    letter-spacing: -0.01rem;

    &.bold {
      font-weight: bold;
    }
  }

  ${props =>
    props.full &&
    css`
      width: 100%;

      .value {
        font-size: 1.5rem;
      }
    `}

  ${props =>
    props.right &&
    css`
      text-align: right;
    `}
`

const spin = keyframes`
    0%{
        transform: none; 
    }

    100% {
        transform: rotateZ(360deg);
    }
`

const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0 226 193 / 98%);
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  flex-direction: column;
  visibility: hidden;

  span {
    padding-top: 1rem;
    font-size: 0.875rem;
  }

  &.is-loading,
  &.is-init {
    opacity: 1;
    visibility: visible;

    svg {
      animation-duration: 0.3s;
    }
  }

  svg {
    height: 3rem;
    width: 3rem;
    animation: ${spin} 0s linear both infinite;
  }
`

const Planner = () => {
  const { firstDeposit, monthlyDeposit, investmentYears } =
    useGlobalStateContext()
  const [status, setStatus] = useState("is-init")
  const [worstCase, setWorstCase] = useState()
  const [bestCase, setBestCase] = useState()
  const [deposits, setDeposits] = useState()
  const [mostLikely, setMostLikely] = useState()
  const [profile, setProfile] = useState(null)

  //get the allocation profile
  useEffect(() => {
    const getPortfolioAllocation = async () => {
      try {
        const res = await fetch(
          "https://ultima259.myxeno.com/api/v1/guest/profiler/evaluate",
          {
            method: "POST",
            headers: new Headers({
              "Content-type": "application/x-www-form-urlencoded",
            }),
            body: "goalCode=EMERGENCIES&riskScore=1",
          }
        )
        const data = await res.json()
        if (data) {
          setProfile(data.profile)
        }
      } catch (error) {
        alert("Sorry, there was an error please reload the page.")
      }
    }

    getPortfolioAllocation()
  }, [])

  //set defaults
  useEffect(() => {
    if (profile !== null) {
      computeResults(profile)
    }
  }, [profile])

  //listen for changes
  const computeResults = args => {
    setTimeout(() => {
      let moneyFormat = wNumb({
        thousand: ",",
        prefix: "UGX ",
        decimals: 0,
      })

      let data = getAverageReturnSeries(
        parseInt(firstDeposit),
        parseInt(monthlyDeposit),
        args.averageRate,
        args.lowerRate,
        args.upperRate,
        parseInt(investmentYears)
      )

      let finalYear = data[parseInt(investmentYears) - 1]

      setDeposits(
        moneyFormat.to(finalYear.totalContributions + parseInt(firstDeposit))
      )
      setBestCase(moneyFormat.to(finalYear.returnRangeSeries[2]))
      setWorstCase(moneyFormat.to(finalYear.returnRangeSeries[1]))
      setMostLikely(moneyFormat.to(finalYear.averageReturnSeries[1]))
    }, 250)

    setTimeout(() => {
      setStatus("")
    }, 250)
  }

  const triggerUpdate = () => {
    if (status === "") {
      setStatus("is-loading")
      computeResults(profile)
    }
  }

  return (
    <Grid className="row">
      <Controls>
        <Slider
          label="First Deposit"
          unit="UGX"
          min={10000}
          max={10000000}
          start={1000000}
          step={5000}
        />
        <Slider
          label="Monthly Deposit"
          unit="UGX"
          min={10000}
          max={10000000}
          start={1000000}
          step={5000}
        />
        <Slider
          unit="years"
          label="Investment Period"
          min={2}
          max={10}
          start={4}
          step={2}
          className="last-child"
        />
        <SimulateBtn className={status}>
          <button
            onClick={() => {
              triggerUpdate()
            }}
          >
            <span>Simulate</span>
            <Icon title="arrow-ltr" fill="#00e2c1" />
          </button>
        </SimulateBtn>
      </Controls>
      <Results>
        <Loader className={status}>
          <Icon title="loader" />
          <span>simulating</span>
        </Loader>
        <div>
          <Row full>
            <Overline>Your Deposits</Overline>
            <span className="value bold">{deposits}</span>
          </Row>
          <Row full>
            <Overline>* Most likely</Overline>
            <span className="value bold">{mostLikely}</span>
          </Row>
          <Row>
            <Overline>* Best Case</Overline>
            <span className="value">{bestCase}</span>
          </Row>
          <Row right>
            <Overline>* Worst Case</Overline>
            <span className="value">{worstCase}</span>
          </Row>
          <Row>
            <Overline>Strategy</Overline>
            <span className="value">Conservative</span>
          </Row>
          <Row right>
            <Overline>Risk Score</Overline>
            <span className="value">1/10</span>
          </Row>
        </div>
      </Results>
    </Grid>
  )
}
export default Planner
