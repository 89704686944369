import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { vw } from "../../utilities/pxtovw"
import { rem } from "../../utilities/pxtorem"
import Title from "./title"
import Text from "./text"
import Photo from "./photo"
import Button from "../shared/button"
import Icon from "../shared/icon"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import GetStartedBtn from "./get-started-btn"
import { StaticImage } from "gatsby-plugin-image"
import Overline from "./overline"
import PhoneMockup from "../../images/groups/phone-mockup.png"

const Box = styled.section`
  height: ${vw(647)};

  @media screen and (max-width: 1023px) {
    height: 55vw;
  }

  @media screen and (max-width: 766px) {
    height: auto;
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 766px) {
    display: block;
  }
`

const Copy = styled.div`
  height: 100%;
  flex: 1;
  > section {
    padding: ${vw(130)} ${vw(103)} 0;
  }

  p:last-child {
    padding-bottom: ${rem(36)};
  }

  @media screen and (max-width: 1023px) {
    flex: 2;
  }

  @media screen and (max-width: 766px) {
    height: auto;

    > section {
      padding: 2rem;
      padding-top: 5rem;
      padding-bottom: 4rem;
    }
  }
`

const Image = styled.div`
  flex: 1;
  position: relative;

  .gatsby-image-wrapper {
    height: 41.84375vw;
  }

  figure:after {
    left: -10%;
    bottom: 10%;
  }

  .xn-pattern {
    position: absolute;
    top: ${vw(46)};
    height: ${vw(42)};
    width: ${vw(108)};
    left: -${vw(108)};
  }

  @media screen and (max-width: 1023px) {
    height: 45vw;
  }

  @media screen and (max-width: 766px) {
    height: auto;

    .gatsby-image-wrapper {
      height: auto;
    }

    .xn-pattern {
      top: -1.8rem;
      left: 0;
      width: 1.8rem;
      height: 2rem;
    }

    figure:after {
      bottom: 0;
    }
  }
`

const Actions = styled.div`
  > div {
    margin-right: 1.5rem;

    &.outline {
      span {
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 766px) {
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 0.5rem;
      padding-bottom: 0;
      margin-bottom: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const Video = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.92);
  backdrop-filter: blur(5px);
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  height: 100%;

  &.is-false {
    display: none;
  }

  &.is-true {
    display: flex;
  }
`

const scaleIn = keyframes`
  0% {
    transform: scale3d(0.75,0.75,0.75);
    opacity: 0; 
  }

  100%{
    transform: none; 
    opacity: 1; 
  }
`

const Embed = styled.div`
  width: 65%;
  position: relative;
  animation: ${scaleIn} 0.75s ease both;

  .close-btn {
    position: absolute;
    left: -2.25rem;
    top: -2.25rem;
    z-index: 2;

    svg {
      width: 1.35rem;
      height: 1.35rem;
      object-fit: contain;
    }
  }

  > div {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    background: gainsboro;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const Mockup = styled.figure`
  position: absolute;
  width: 11rem;
  height: 22rem;
  left: -4rem;
  z-index: 10;
  bottom: 5rem;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 766px) {
    left: 1.5rem;
    width: 7.5rem;
    height: 15rem;
    bottom: 0;
  }
`

const Splash = () => {
  const [videoState, setVideoState] = useState(false)
  const toggleVideo = state => {
    setVideoState(state)
    logEvent("Group Investment - Watch Video")
  }

  const logEvent = label => {
    if (typeof window !== "undefined") {
      trackCustomEvent({
        category: label,
        action: "Click",
        label: "Group Investment Campaign",
        value: 1,
      })
    }
  }

  return (
    <Box>
      <Grid>
        <Copy>
          <section>
            {/* <Overline>Join XENO</Overline> */}
            <Title>
              <h1>Get more out of your group investment</h1>
            </Title>
            <Text>
              <p>
                XENO helps you plan, save and invest for all your group goals,
                on one collaborative platform. Get a free investment plan for
                your SACCO, Investment Club or family.
              </p>
            </Text>
            <Actions>
              <GetStartedBtn
                title="Get Free Plan"
                className="inline"
                section="splash"
              />
              <Button
                label="Watch Our Story"
                theme="outline"
                click={() => {}}
              />
            </Actions>
          </section>
        </Copy>
        <Image>
          <Mockup>
            <img src={PhoneMockup} alt="" />
          </Mockup>
          <Photo>
            <StaticImage
              src="https://res.cloudinary.com/xeno-technologies/image/upload/c_scale,q_auto:good,w_1000/v1657220745/shutterstock_1608266425_flpxic.jpg"
              alt="two ladies and a guy looking at a phone with XENO app"
              loading="eager"
              backgroundColor="#1a2b35"
            />
          </Photo>
        </Image>
      </Grid>
      <Video className={"is-" + videoState}>
        <Embed>
          <button className="close-btn" onClick={() => {}}>
            <Icon title="close" fill="#fff" />
          </button>
          <div>
            {videoState && (
              <iframe
                src="https://www.youtube.com/embed/dTNWKp27dg0?modestbranding=1"
                title="XENO Investment"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </Embed>
      </Video>
    </Box>
  )
}

export default Splash
