import React from "react"
import styled from "styled-components"
import { rem } from "../../utilities/pxtorem"
import { useInView } from "react-intersection-observer"

const Box = styled.div`
  position: relative;
  opacity: 0;
  transition: opacity 0.5s;
  /* background-image: linear-gradient(4deg, #009fff, #00e2c1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */

  h1,
  h2,
  blockquote p {
    font-family: var(--xn-font-title);
    font-style: normal;
    font-weight: bold;
    font-size: ${rem(36)};
    line-height: ${rem(46)};
    color: ${props => props.theme.mint};
    padding-bottom: ${rem(52)};

    &.small {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }

  blockquote {
    p {
      padding-bottom: 0;
    }

    cite {
      padding-top: 1rem;
      padding-bottom: ${rem(52)};
      text-align: right;
      display: block;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.darkblue};
    transition: transform 0.75s ease-in-out;
    transform-origin: 100% 0;
  }

  &.in-view {
    opacity: 1;
    &:after {
      transform: scale3d(0, 1, 1);
    }
  }

  @media screen and (max-width: 766px) {
    h1,
    h2,
    blockquote p {
      font-size: 2.25rem;
      padding-bottom: 2.5rem;
      line-height: 2.65rem;
    }
  }
`

const Ele = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  return (
    <Box ref={ref} className={inView ? "in-view" : ""}>
      {children}
    </Box>
  )
}

export default Ele
