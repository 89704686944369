import React from "react"
import styled, { css } from "styled-components"
import { rem } from "../../utilities/pxtorem"

const Box = styled.span`
  font-family: var(--xn-font-body);
  font-style: normal;
  font-weight: 500;
  font-size: ${rem(11)};
  line-height: 1.9375;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: var(--c-white);
  display: block;
  opacity: 0.3;

  ${props =>
    props.invert &&
    css`
      @media (prefers-color-scheme: light) {
        color: var(--c-blue-200);
      }
    `}
`
const Ele = ({ children, invert }) => {
  return (
    <Box invert={invert} className="xn-overline">
      {children}
    </Box>
  )
}

export default Ele
