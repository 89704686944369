import React from "react"
import Meta from "../utilities/seo"
import Logo from "../components/education/logo"
import ScheduleCallBtn from "../components/education/schedule-call-button"
import Footer from "../components/layout/footer"

import Main from "../components/education/main"
import Splash from "../components/education/splash"
import Planning from "../components/education/planning"
import Saving from "../components/education/saving"
import Investment from "../components/education/investment"

const Groups = () => {
  return (
    <>
      <Meta
        title="Group Investment"
        image={{
          url: "https://res.cloudinary.com/xeno-technologies/image/upload/v1657220745/shutterstock_1608266425_flpxic.jpg",
          height: "5308",
          width: "3762",
        }}
        description="XENO helps you plan, save and invest for all your group goals, on one collaborative platform. Get a a free investment plan for your SACCO, Investment Clubs or Family."
      />
      <Main>
        <Logo className="med float" />
        <ScheduleCallBtn />
        <Splash />
        <Planning />
        <Saving />
        <Investment />
        <Footer theme="light" />
      </Main>
    </>
  )
}

export default Groups
