import React from "react"
import styled from "styled-components"
import Title from "./title"
import Overline from "./overline"
import Section from "./section"
import Grid from "./column-grid"
import Photo from "./photo"
import FeatureItem from "./feature-item"
import { vw } from "../../utilities/pxtovw"
import GetStartedBtn from "./get-started-btn"
import { StaticImage } from "gatsby-plugin-image"

const Copy = styled.section`
  width: ${vw(739)};
  padding-left: ${vw(108)};

  ul {
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 50%;
    }
  }

  @media screen and (max-width: 766px) {
    width: 100%;
    padding: 2rem;

    ul {
      padding-top: 1.5rem;
      display: block;

      li {
        width: 100%;
      }
    }

    p {
      line-height: 1.6;
    }
  }
`

const Image = styled.div`
  width: ${vw(413)};
  height: ${vw(427)};

  figure:after {
    top: -10%;
    left: -10%;
  }

  .xn-pattern {
    position: absolute;
    width: ${vw(44)};
    height: ${vw(72)};
    bottom: -${vw(72)};
    right: ${vw(158)};
  }

  @media screen and (max-width: 766px) {
    width: 100%;
    height: auto;

    .xn-pattern {
      width: 1.95rem;
      height: 2.25rem;
      bottom: auto;
      top: -2rem;
      right: 0;
      z-index: 1;
    }
  }
`

const Saving = () => {
  return (
    <Section className="clear-bottom">
      <Grid className="row">
        <Copy>
          <Overline>Saving</Overline>
          <Title>
            <h2>
              XENO helps groups invest efficiently with complete transparency
            </h2>
          </Title>
          <ul>
            <ul>
              <FeatureItem
                label="Free Advice"
                caption="Your group will access the best professional guidance from our Investment Advisors."
                icon="award"
              />
              <FeatureItem
                label="Ease Deposits"
                caption="Members can deposit the recommended amount through MTN Mobile Money (zero charges), Bank or Airtel"
                icon="plus-fill"
              />
              <FeatureItem
                label="Transparent Monitoring"
                caption="Members can use our website, app, or MTN USSD to monitor investment performance."
                icon="phone"
              />
              <FeatureItem
                label="Manage Withdraws"
                caption="Members can withdraw any amount needed through administrators"
                icon="arrow-ltr"
              />
            </ul>
          </ul>
          <GetStartedBtn
            title="Start Saving"
            className="padd-top-med"
            section="saving"
          />
        </Copy>
        <Image>
          <Photo>
            <StaticImage
              src="https://res.cloudinary.com/xeno-technologies/image/upload/v1656777883/happy-group_clkgdr.jpg"
              alt="Man with lady drinking tea"
              objectFit="cover"
            />
          </Photo>
        </Image>
      </Grid>
    </Section>
  )
}
export default Saving
