import React from "react"
import styled from "styled-components"
import { rem } from "../../utilities/pxtorem"

const Box = styled.span`
  font-family: var(--xn-font-body);
  font-style: normal;
  font-weight: 600;
  font-size: ${rem(12)};
  line-height: 1.9375;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  display: block;
`
const Ele = ({ children }) => {
  return <Box>{children}</Box>
}

export default Ele
