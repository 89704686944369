import React from "react"
import styled from "styled-components"
import Overline from "./overline"
import { rem } from "../../utilities/pxtorem"
import Icon from "../shared/icon"
import FadeIn from "../shared/fade-up-inview"

const Box = styled.li`
  padding-bottom: ${rem(72)};
  span {
    color: ${props => props.theme.mint};
  }

  svg {
    width: 1.875rem;
    height: 1.875rem;
    margin-bottom: ${rem(18)};
  }

  p {
    padding-top: ${rem(24)};
    padding-right: 1rem;
    line-height: 1.6;
  }

  @media screen and (max-width: 766px) {
    &:last-child {
      padding-bottom: 0;
    }

    p {
      padding-right: 0;
    }
  }
`
const Ele = ({ icon, label, caption }) => {
  return (
    <Box>
      <FadeIn>
        <Icon title={icon} />
        <Overline>{label}</Overline>
        <p>{caption}</p>
      </FadeIn>
    </Box>
  )
}
export default Ele
