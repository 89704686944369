import React from "react"
import styled from "styled-components"
import Title from "./title"
import Text from "./text"
import Overline from "./overline"
import Photo from "./photo"
import { vw } from "../../utilities/pxtovw"
import AccordionItem from "./accordion-item"
import { rem } from "../../utilities/pxtorem"
import Section from "./section"
import Grid from "./column-grid"
import GetStartedBtn from "./get-started-btn"
import { StaticImage } from "gatsby-plugin-image"
import Icon from "../shared/icon"

const Image = styled.div`
  width: ${vw(413)};
  height: ${vw(377)};

  .xn-pattern {
    width: ${vw(44)};
    height: ${vw(72)};
    left: ${vw(108)};
    position: absolute;
    top: -${vw(72)};
  }

  figure:after {
    right: -10%;
    bottom: -10%;
  }

  @media screen and (max-width: 766px) {
    width: 100%;
    height: auto;

    .xn-pattern {
      width: 1.2rem;
      height: 2rem;
      top: -2rem;
    }

    figure:after {
      right: 0;
    }
  }
`

const Copy = styled.div`
  width: ${vw(630)};
  margin-right: ${vw(108)};

  /* ul {
    padding-top: ${rem(72)};
  } */

  @media screen and (min-width: 1300px) {
    padding-left: ${vw(0)};
  }

  @media screen and (max-width: 766px) {
    width: 100%;
    padding: 2rem;

    ul {
      padding-bottom: 3rem;
    }
  }
`

const BenefitsLists = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: stretch;

  h3 {
    font-weight: bold;
    color: var(--c-mint-100);
    padding-bottom: 0.5rem;
  }

  li {
    width: 50%;
    height: 100%;
  }

  p {
    line-height: 1.6;
    font-weight: 500;
    font-size: 1.0625rem;
    letter-spacing: -0.2px;
  }

  span {
    height: 2rem;
    width: 2rem;
    /* background-color: var(--c-mint-100); */
    background-image: linear-gradient(4deg, #009fff, #00e2c1);
    border-radius: 100%;
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -0.5rem;
    top: -0.5rem;

    path {
      /* stroke: #000; */
      stroke: #000;
      transform: scale(0.55);
      transform-origin: 50%;
    }
  }

  div {
    /* background-color: var(--c-mint-100); */
    background-image: linear-gradient(135deg, #0d404b, #11171f 50%);
    padding: 2rem;
    padding-right: 1rem;
    border-radius: 12px;
    margin: 1rem 1rem 2rem;
    position: relative;
  }

  @media (max-width: 766px) {
    li {
      width: 100%;
    }
  }
`

const Planning = () => {
  const benefits = [
    {
      title: "Transparent",
      caption:
        "Free customized group investment plan using goal-based approach",
    },
    {
      title: "Safe",
      caption: "Run several group goals under one investment account",
    },
    {
      title: "Accessible",
      caption: "Intuitive platform to collect & track deposits from members.",
    },
    {
      title: "Professional",
      caption:
        "A diversified and tax-efficient investment through XENO unit trust funds.",
    },
    {
      title: "Cost Friendly",
      caption:
        "Transparently track group performance and progress towards goal.",
    },
    {
      title: "Cost Friendly",
      caption:
        "Periodic local, regional and international investment research and insights.",
    },
  ]
  return (
    <Section className="padd-top-small clear-bottom">
      <Grid>
        <Copy>
          <section>
            <Overline>Planning</Overline>
            <Title>
              <h2>
                XENO simplifies investment for SACCOs, Investment Clubs,
                Families and Retirement Schemes
              </h2>
            </Title>
          </section>
          <BenefitsLists>
            {benefits.map((ele, index) => {
              return (
                <li key={index}>
                  <div>
                    <span>
                      <Icon title="check" />
                    </span>
                    <p>{ele.caption}</p>
                  </div>
                </li>
              )
            })}
          </BenefitsLists>
          <GetStartedBtn
            title="Start Planning"
            className="padd-top-large"
            section="planning"
          />
        </Copy>
        <Image>
          <Photo>
            <StaticImage
              src="https://res.cloudinary.com/xeno-technologies/image/upload/v1656777881/corporate-ugandans_gathdx.jpg"
              alt="Lady using tablet"
              objectFit="cover"
            />
          </Photo>
        </Image>
      </Grid>
    </Section>
  )
}
export default Planning
