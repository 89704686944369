import React from "react"
import styled from "styled-components"

const Box = styled.div`
  background-color: ${props => props.theme.darkblue};
  color: #fff;

  > div {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  @media screen and (min-width: 1919px) {
    > div {
      width: 100%;
    }
  }
`

const Wrap = ({ children }) => {
  return (
    <Box>
      <div>{children}</div>
    </Box>
  )
}

export default Wrap
