import React from "react"
import styled from "styled-components"

const Box = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: space-between;

  &.row {
    flex-direction: row;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  @media screen and (max-width: 766px) {
    display: block;
  }
`

const Ele = ({ children, className }) => {
  return <Box className={className}>{children}</Box>
}
export default Ele
