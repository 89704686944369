import * as React from "react"
import styled from "styled-components"
import { useState } from "react"

const Box = styled.div`
  position: fixed;
  right: 1.5rem;
  top: 1rem;
  z-index: 100;
  a {
    letter-spacing: -0.2px;
    color: #000;
    padding: 0.625rem 1.125rem;
    border-radius: 2rem;
    font-size: 0.75rem;
    font-weight: 500;
    background-color: #00e2c1;

    &:hover {
      color: #fff;
      background-color: #000;
    }
  }
  @media (max-width: 766px) {
    right: 1rem;
  }
`

const Ele = () => {
  const [advisor, setAdvisor] = useState("")
  React.useEffect(() => {
    let url = "https://meetings.hubspot.com/"
    // let advisors = ["sharon-tumushabe", "joshua-asiimwe"]
    let advisors = ["joshua-asiimwe"]
    let advisor = advisors[Math.floor(Math.random() * advisors.length)]
    url = url + advisor
    setAdvisor(url)
  }, [])

  return (
    <>
      <Box>
        <a target="_blank" title="Talk to a XENO Advisor" href={advisor}>
          Talk to Advisor
        </a>
      </Box>
    </>
  )
}
export default Ele
