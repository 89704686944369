export const socials = [
  {
    icon: "facebook",
    title: "XENO Facebook",
    url: "https://www.facebook.com/XenoKenya",
  },
  {
    icon: "twitter",
    title: "XENO Twitter",
    url: "https://twitter.com/XenoKenya",
  },
  {
    icon: "whatsapp",
    title: "XENO WhatsApp",
    url: "https://web.whatsapp.com/send?phone=254723459284&text&app_absent=0",
  },
  {
    icon: "youtube",
    title: "XENO Youtube",
    url: "https://www.youtube.com/@xenoinvestment",
  },
]
