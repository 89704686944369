import React from "react"
import styled from "styled-components"
import Pattern from "../shared/pattern"
import { useInView } from "react-intersection-observer"

const Box = styled.figure`
  position: relative;
  width: 100%;
  height: 100%;

  &:after {
    content: "";
    position: absolute;
    width: 52%;
    padding-bottom: 52%;
    background: ${props => props.theme.mint};
    opacity: 0.04;
    z-index: 0;
    display: none;
  }

  .gatsby-image-wrapper {
    z-index: 2;
  }

  .xn-pattern {
    opacity: 0.3;
  }

  @media screen and (max-width: 766px) {
    &:after {
      z-index: 1;
      opacity: 0.15;
      width: 35%;
      padding-bottom: 35%;
    }
  }
`
const Ele = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  return (
    <Box ref={ref} className={inView ? "in-view" : ""}>
      <Pattern size="4" type="dot" />
      {children}
    </Box>
  )
}
export default Ele
