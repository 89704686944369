import React from "react"
import styled from "styled-components"
import { rem } from "../../utilities/pxtorem"
import FadeIn from "../shared/fade-up-inview"

const Box = styled.div`
  p {
    font-family: var(--xn-font-body);
    font-style: normal;
    font-weight: normal;
    line-height: ${rem(30)};
    color: #fff;
  }
`
const Ele = ({ children }) => {
  return (
    <FadeIn>
      <Box>{children}</Box>
    </FadeIn>
  )
}
export default Ele
